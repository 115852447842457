import Link from "next/link";
import { ReactNode } from "react";
import { TextLink } from "src/common/ui/TextLink";

export interface FooterLinkProps {
  children: ReactNode;
  href: string;
}

export function FooterLink({ children, href }: FooterLinkProps) {
  return (
    <TextLink
      variant="text-white"
      className="py-2 md:py-1"
      as={Link}
      href={href}
    >
      {children}
    </TextLink>
  );
}
