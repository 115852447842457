import { MouseEventHandler } from "react";
import { HamburgerIcon } from "src/common/components/HamburgerIcon";

export interface HamburgerButtonProps {
  isOpen: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  title: string;
  className?: string;
}

export function HamburgerButton({
  isOpen,
  onClick,
  title,
  className = "",
}: HamburgerButtonProps) {
  return (
    <button
      onClick={onClick}
      className={`-ring-offset-3 inline-grid h-20 w-20 place-items-center focus-visible:outline-1.5 focus-visible:outline-offset-[-8px] focus-visible:outline-black ${className}`}
      title={title}
    >
      <HamburgerIcon isOpen={isOpen} size="md" />
    </button>
  );
}
