import { IconProps } from "src/common/icons/types";

export function Counter(props: IconProps) {
  return (
    <svg
      height="121"
      width="484"
      fill="none"
      viewBox="0 0 484 121"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_29_21)">
        <path
          d="M80.815 4H42.7303V41.692V79.384H4.25308C4.25308 79.384 -1.56007 41.692 42.5341 41.692C86.6282 41.692 80.815 4 80.815 4Z"
          stroke="currentColor"
          strokeWidth="6.96857"
        />
        <path
          d="M119.349 41.6886H81.2645V117.073H42.7873C42.7873 117.073 36.9741 79.3806 81.0682 79.3806C125.162 79.3806 119.349 41.6886 119.349 41.6886Z"
          stroke="currentColor"
          strokeWidth="6.96857"
        />
        <path
          d="M200.056 47.494H203.204L202.25 27.3623H198.816L195.667 32.1328C192.041 28.9843 187.462 26.7898 181.642 26.7898C165.899 26.7898 152.159 39.3841 152.159 59.4205C152.159 79.266 165.994 91.6695 181.451 91.6695C192.232 91.6695 199.674 86.7081 204.254 78.8844L200.628 76.022C196.43 82.7962 189.942 86.0402 182.977 86.0402C171.051 86.0402 161.987 74.7817 161.987 58.9434C161.987 41.4831 170.383 31.942 181.642 31.942C189.084 31.942 196.049 37.1896 200.056 47.494Z"
          fill="currentColor"
        />
        <path
          d="M211.314 68.2937C211.314 82.4146 220.76 91.6695 232.114 91.6695C243.468 91.6695 252.914 82.4146 252.914 68.2937C252.914 54.1728 243.468 44.9179 232.114 44.9179C220.76 44.9179 211.314 54.1728 211.314 68.2937ZM244.136 68.2937C244.136 79.8385 239.938 86.8989 232.114 86.8989C224.29 86.8989 220.092 79.8385 220.092 68.2937C220.092 56.7489 224.29 49.6885 232.114 49.6885C239.938 49.6885 244.136 56.7489 244.136 68.2937Z"
          fill="currentColor"
        />
        <path
          d="M307.394 89.6658V87.5668L303.005 85.5632C301.287 84.7999 300.81 84.5136 300.81 82.51V45.8721H299.474L285.831 46.8262V50.1656L290.41 51.0243C292.223 51.4059 292.605 52.0738 292.605 54.0774V80.0293C289.838 83.0825 286.117 85.6586 281.728 85.6586C276.385 85.6586 273.332 82.1283 273.332 75.1633V45.8721H271.996L258.352 46.8262V50.1656L262.932 51.0243C264.745 51.4059 265.126 52.0738 265.126 54.0774V75.7358C265.126 85.4677 269.992 91.6695 278.293 91.6695C284.495 91.6695 289.743 87.376 292.605 83.6549V91.6695H293.941L307.394 89.6658Z"
          fill="currentColor"
        />
        <path
          d="M333.536 86.6127C327.812 86.6127 327.335 86.4219 327.335 84.7999V56.7489C330.006 53.8866 334.3 51.1197 338.879 51.1197C344.795 51.1197 347.562 54.9361 347.562 61.6149V84.7999C347.562 86.4219 347.085 86.6127 341.36 86.6127V90.7154H361.969V86.6127C356.244 86.6127 355.767 86.4219 355.767 84.7999V60.8516C355.767 52.36 352.142 44.9179 342.887 44.9179C336.399 44.9179 330.197 49.6885 327.335 53.4095V44.9179H325.999L312.546 48.8298V50.9289L316.935 52.9325C318.652 53.6958 319.129 53.982 319.129 55.9857V84.7999C319.129 86.4219 318.652 86.6127 312.927 86.6127V90.7154H333.536V86.6127Z"
          fill="currentColor"
        />
        <path
          d="M399.37 84.2274L396.889 81.1742C394.218 84.132 391.451 85.9448 388.112 85.9448C383.532 85.9448 380.956 83.5595 380.956 78.5981V50.5472H395.172V45.8721H380.956V32.0374L372.75 34.4227V45.8721H366.644V50.5472H372.75V80.888C372.75 87.1851 377.903 91.6695 384.772 91.6695C390.211 91.6695 395.744 89.1888 399.37 84.2274Z"
          fill="currentColor"
        />
        <path
          d="M441.542 81.5559L438.012 79.0752C434.386 84.0366 431.142 85.9448 426.181 85.9448C418.548 85.9448 411.774 79.6477 411.774 67.3396V67.0534H441.351V64.0002C441.351 53.4095 435.913 44.9179 424.272 44.9179C413.491 44.9179 403.664 54.8407 403.664 68.7708C403.664 83.0825 413.491 91.6695 424.368 91.6695C431.428 91.6695 438.298 88.0438 441.542 81.5559ZM423.891 49.5931C430.665 49.5931 433.241 54.7453 433.337 62.8553H412.06C413.3 54.1728 418.262 49.5931 423.891 49.5931Z"
          fill="currentColor"
        />
        <path
          d="M472.551 86.6127C463.964 86.6127 463.487 86.4219 463.487 84.609V61.8057C466.063 57.9893 470.165 53.2187 472.837 53.0279C475.031 53.0279 476.176 55.7948 478.848 55.7948C481.71 55.7948 484 53.982 484 50.6426C484 47.7803 481.901 44.9179 477.703 44.9179C472.264 44.9179 466.349 52.7417 463.487 57.6076V44.9179H462.151L448.698 48.8298V50.9289L453.087 52.9325C454.804 53.6958 455.281 53.982 455.281 55.9857V84.7999C455.281 86.4219 454.804 86.6127 449.079 86.6127V90.7154H472.551V86.6127Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_29_21">
          <rect height="121" width="484" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
