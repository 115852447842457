import { IconProps } from "src/common/icons/FontAwesome/types";

export function Doctor(props: IconProps) {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g transform="translate(-53, -23)">
        <path
          d="M82.1944 80.6115C98.0676 80.6115 110.935 67.7437 110.935 51.8706C110.935 35.9975 98.0676 23.1298 82.1944 23.1298C66.3213 23.1298 53.4536 35.9975 53.4536 51.8706C53.4536 67.7437 66.3213 80.6115 82.1944 80.6115Z"
          fill="#18494D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.0723 50.5935C68.6254 50.5935 67.4524 51.7665 67.4524 53.2134C67.4524 54.6604 68.6254 55.8334 70.0723 55.8334C71.5193 55.8334 72.6923 54.6604 72.6923 53.2134C72.6923 51.7665 71.5193 50.5935 70.0723 50.5935ZM66.5542 53.2134C66.5542 51.2704 68.1293 49.6953 70.0723 49.6953C72.0153 49.6953 73.5905 51.2704 73.5905 53.2134C73.5905 55.1564 72.0153 56.7315 70.0723 56.7315C68.1293 56.7315 66.5542 55.1564 66.5542 53.2134Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.5232 59.4444C66.5232 57.4915 68.1193 55.8953 70.0723 55.8953C71.9118 55.8953 73.4347 57.3113 73.6055 59.1077H75.9624C77.0628 59.1077 77.9615 60.0063 77.9615 61.1068C77.9615 61.513 77.8391 61.8916 77.6293 62.2077H86.8196C86.6098 61.8916 86.4874 61.513 86.4874 61.1068C86.4874 60.0074 87.385 59.1077 88.4865 59.1077H90.8434C91.0143 57.3114 92.5371 55.8953 94.3766 55.8953C96.3297 55.8953 97.9257 57.4913 97.9257 59.4444V66.0668C97.9257 66.3148 97.7247 66.5159 97.4767 66.5159H66.9723C66.7243 66.5159 66.5232 66.3148 66.5232 66.0668V59.4444ZM88.4758 62.2077H92.8267C93.4311 62.2077 93.9276 61.7113 93.9276 61.1068V59.5845C93.9276 59.3365 94.1287 59.1354 94.3767 59.1354C94.6247 59.1354 94.8258 59.3365 94.8258 59.5845V61.1068C94.8258 62.2073 93.9272 63.1059 92.8267 63.1059H71.6224C70.5219 63.1059 69.6233 62.2073 69.6233 61.1068V59.5845C69.6233 59.3365 69.8244 59.1354 70.0724 59.1354C70.3204 59.1354 70.5214 59.3365 70.5214 59.5845V61.1068C70.5214 61.7113 71.0179 62.2077 71.6224 62.2077H75.9655C75.967 62.2077 75.9685 62.2077 75.97 62.2077C76.571 62.2037 77.0633 61.7088 77.0633 61.1068C77.0633 60.5023 76.5668 60.0059 75.9624 60.0059H73.1723C72.9243 60.0059 72.7233 59.8048 72.7233 59.5568V59.4444C72.7233 57.9875 71.5293 56.7934 70.0723 56.7934C68.6153 56.7934 67.4213 57.9875 67.4213 59.4444V65.6177H97.0276V59.4444C97.0276 57.9874 95.8336 56.7934 94.3766 56.7934C92.9196 56.7934 91.7257 57.9876 91.7257 59.4444V59.5568C91.7257 59.8048 91.5246 60.0059 91.2766 60.0059H88.4865C87.8816 60.0059 87.3856 60.5028 87.3856 61.1068C87.3856 61.7061 87.8737 62.1994 88.4713 62.2077C88.4728 62.2077 88.4743 62.2077 88.4758 62.2077Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.7618 53.0655C91.6799 54.5101 92.7846 55.7476 94.2293 55.8295C95.6739 55.9114 96.9114 54.8067 96.9933 53.362C97.0752 51.9174 95.9705 50.6799 94.5258 50.598C93.0812 50.5161 91.8437 51.6208 91.7618 53.0655ZM94.1784 56.7262C92.2385 56.6163 90.7551 54.9545 90.8651 53.0146C90.9751 51.0747 92.6368 49.5913 94.5767 49.7013C96.5166 49.8112 98 51.473 97.89 53.4129C97.7801 55.3528 96.1183 56.8362 94.1784 56.7262Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.1755 37.9956C81.8734 37.9956 81.5762 38.0153 81.2845 38.0533C81.0386 38.0853 80.8133 37.9118 80.7813 37.6659C80.7493 37.4199 80.9227 37.1946 81.1686 37.1626C81.4983 37.1197 81.8343 37.0974 82.1755 37.0974C86.4469 37.0974 89.9097 40.5601 89.9097 44.8316C89.9097 46.4493 89.4122 47.952 88.5624 49.1938L89.0821 51.2045C89.1223 51.36 89.0764 51.5251 88.9617 51.6375C88.847 51.75 88.681 51.7926 88.5264 51.7493L86.5638 51.2004C85.317 52.0611 83.8047 52.5657 82.1755 52.5657C80.5075 52.5657 78.962 52.0369 77.6985 51.1385L75.5146 51.7493C75.36 51.7925 75.194 51.7499 75.0793 51.6375C74.9646 51.525 74.9187 51.3599 74.9589 51.2044L75.5672 48.8514C74.8529 47.6798 74.4414 46.3031 74.4414 44.8315C74.4414 43.061 75.0372 41.4287 76.0385 40.1251C76.1895 39.9284 76.4715 39.8914 76.6682 40.0425C76.8649 40.1936 76.9018 40.4755 76.7508 40.6722C75.8657 41.8245 75.3396 43.2659 75.3396 44.8315C75.3396 46.1964 75.7392 47.4666 76.4276 48.5331C76.4957 48.6385 76.5165 48.7676 76.4851 48.889L76.0236 50.6743L77.6669 50.2147C77.8019 50.177 77.9467 50.2044 78.0586 50.2889C79.2038 51.1543 80.629 51.6675 82.1755 51.6675C83.6864 51.6675 85.0814 51.1775 86.2128 50.348C86.324 50.2665 86.4665 50.2406 86.5993 50.2777L88.0174 50.6744L87.6408 49.2174C87.6072 49.0876 87.6334 48.9497 87.7121 48.8412C88.5295 47.7149 89.0115 46.3302 89.0115 44.8316C89.0115 41.0561 85.9509 37.9956 82.1755 37.9956Z"
          fill="#00999E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.1724 62.5176C73.4204 62.5176 73.6214 62.7186 73.6214 62.9667V65.7567C73.6214 66.0047 73.4204 66.2058 73.1724 66.2058C72.9244 66.2058 72.7233 66.0047 72.7233 65.7567V62.9667C72.7233 62.7186 72.9244 62.5176 73.1724 62.5176ZM91.2766 62.5176C91.5246 62.5176 91.7257 62.7186 91.7257 62.9667V65.7567C91.7257 66.0047 91.5246 66.2058 91.2766 66.2058C91.0286 66.2058 90.8275 66.0047 90.8275 65.7567V62.9667C90.8275 62.7186 91.0286 62.5176 91.2766 62.5176Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.4865 40.4916C80.4865 40.2435 80.6875 40.0425 80.9355 40.0425H83.4156C83.6636 40.0425 83.8646 40.2435 83.8646 40.4916V43.1425H86.5156C86.7636 43.1425 86.9647 43.3435 86.9647 43.5915V46.0716C86.9647 46.3196 86.7636 46.5206 86.5156 46.5206H83.8646V49.1716C83.8646 49.4196 83.6636 49.6207 83.4156 49.6207H80.9355C80.6875 49.6207 80.4865 49.4196 80.4865 49.1716V46.5206H77.8355C77.5875 46.5206 77.3864 46.3196 77.3864 46.0716V43.5915C77.3864 43.3435 77.5875 43.1425 77.8355 43.1425H80.4865V40.4916ZM81.3846 40.9406V43.5915C81.3846 43.8396 81.1835 44.0406 80.9355 44.0406H78.2846V45.6225H80.9355C81.1835 45.6225 81.3846 45.8235 81.3846 46.0716V48.7225H82.9665V46.0716C82.9665 45.8235 83.1675 45.6225 83.4156 45.6225H86.0665V44.0406H83.4156C83.1675 44.0406 82.9665 43.8396 82.9665 43.5915V40.9406H81.3846Z"
          fill="#00999E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M78.284 38.1488C78.4904 38.0112 78.7692 38.0671 78.9067 38.2735L78.9071 38.274C79.0446 38.4804 78.9887 38.7592 78.7823 38.8967C78.5759 39.0342 78.2971 38.9784 78.1596 38.772L78.1593 38.7715C78.0217 38.5651 78.0776 38.2863 78.284 38.1488Z"
          fill="#00999E"
        />
      </g>
    </svg>
  );
}
