import { ReactNode } from "react";
import styles from "./styles.module.css";

export interface EyebrowProps {
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "div" | "span" | "label";
  children: ReactNode;
  className?: string;
  htmlFor?: string;
  id?: string;
}

export function Eyebrow({
  as,
  children,
  className,
  htmlFor,
  id,
}: EyebrowProps) {
  const Element = as ?? "div";

  const letterSpacing = className?.includes("tracking-") ? "" : "tracking-wide";

  return (
    <Element
      className={`${styles.root} font-sans font-medium uppercase leading-tight [word-spacing:0.16rem] ${letterSpacing} ${className}`}
      htmlFor={htmlFor}
      id={id}
    >
      {children}
    </Element>
  );
}
