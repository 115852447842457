import { ReactNode } from "react";

interface LandingSectionProps {
  children: ReactNode;
  className?: string;
  id?: string;
  as?: "div" | "footer" | "section" | "header" | "nav";
}

export function LandingSection({
  children,
  as,
  id = "",
  className = "",
}: LandingSectionProps) {
  const Element = as ?? "section";
  return (
    <Element className={`w-full ${className} relative`} id={id}>
      <div className="relative max-w-[1440px] ml-auto mr-auto box-border px-9">
        {children}
      </div>
    </Element>
  );
}
