export interface HamburgerIconProps {
  isOpen: boolean;
  size: "sm" | "md";
}

export function HamburgerIcon({ isOpen, size }: HamburgerIconProps) {
  return (
    <div
      className={`inline-grid aspect-square items-center ${
        size === "sm" ? "w-5" : "w-8"
      }`}
      data-testid="hamburger-icon"
    >
      <div
        className={`col-[1/2] row-[1/2] h-0.5 bg-current transition-[transform] ease-out-quart ${
          isOpen
            ? "rotate-[-45deg] delay-75 duration-150"
            : "translate-y-1.5 duration-300"
        }`}
      ></div>
      <div
        className={`col-[1/2] row-[1/2] h-0.5 origin-center bg-current transition-[transform] ease-out-quart ${
          isOpen
            ? "scale-x-0 duration-[225ms]"
            : "scale-x-1 delay-75 duration-[225ms]"
        }`}
      ></div>
      <div
        className={`col-[1/2] row-[1/2] h-0.5 bg-current transition-[transform] ease-out-quart ${
          isOpen
            ? "rotate-45 delay-75 duration-150"
            : "-translate-y-1.5 duration-300"
        }`}
      ></div>
    </div>
  );
}
