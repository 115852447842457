"use client";

import { ReactNode, Suspense, useEffect, useState } from "react";
import { RememberReferralCode } from "src/common/components/RememberReferralCode";
import { RememberUTM } from "src/common/components/RememberUTM";
import { Footer } from "src/landing/components/Footer";
import { Navbar } from "src/landing/components/Navbar";
import { MenuProvider } from "src/landing/context/MenuContext";

export interface RootLayoutProps {
  children: ReactNode;
}

export default function LandingLayout(props: RootLayoutProps) {
  const { children } = props;
  const [fadeIn, setFadeIn] = useState(false);
  useEffect(() => {
    document.body.style.overscrollBehavior = "none";
    document.documentElement.style.scrollBehavior = "smooth";
    setFadeIn(true);
  }, [fadeIn, setFadeIn]);
  return (
    <div style={{ transition: "opacity 0.5s linear", opacity: fadeIn ? 1 : 0 }}>
      <Suspense>
        <RememberUTM />
        <RememberReferralCode />
      </Suspense>
      <MenuProvider>
        <Navbar />
      </MenuProvider>
      <div className="-mt-[80px]">
        {children}
        <Footer />
      </div>
    </div>
  );
}
