import { ROUTES } from "src/common/config/routes";
import { Paragraph } from "src/common/ui/Paragraph";
import { FooterLink } from "../FooterLink";
import { LandingSection } from "../LandingSection";
import { Eyebrow } from "src/common/ui/Eyebrow";
import { Heading } from "src/common/ui/Heading";
import { Doctor } from "../icons/Doctor";
import { Button } from "src/common/components/Button";
import Link from "next/link";

export function Footer() {
  return (
    <LandingSection
      as="footer"
      className="bg-gradient-to-b from-[#016D70] to-[#03383A] py-14"
      id="contact"
    >
      <div className="grid grid-cols-12 items-center py-5 lg:py-14">
        <div className="col-[1/13] lg:col-[5/9] flex flex-col items-center">
          <Eyebrow className="mb-2 font-semibold text-counterGreen-300">
            Contact Us
          </Eyebrow>
          <Heading
            display={false}
            className="font-sans text-3xl font-normal text-white leading-tight mb-3 text-balance"
          >
            Have a question?
          </Heading>
          <Paragraph className="mb-8 text-sm leading-normal font-normal text-white text-balance text-center">
            Our Member Care Team is here to help. Leave us your information and
            a member of our team will be in touch!
          </Paragraph>
          <div className="flex items-center mb-8">
            <Doctor className="drop-shadow-[0_14px_24px_rgba(0,0,0,0.25)]" />
            <div className="flex flex-col text-white pl-6 text-lg">
              <strong>Or contact us at</strong>
              <a href="tel:833-603-3864">833-603-3864</a>
            </div>
          </div>
          <Button
            size="lg"
            as={Link}
            variant="primary"
            className="!px-12"
            href={ROUTES.CONTACT_US}
            target="_blank"
          >
            Contact Us
          </Button>
        </div>
        {/* <LandingContactForm className="col-[5/12]" /> */}
      </div>
      <Paragraph
        as="small"
        className="text-xs leading-tight text-center w-full relative max-w-unset text-white block mt-8"
      >
        ©️{new Date().getFullYear()} Counter Health -{" "}
        <FooterLink href={ROUTES.LANDING.TERMS_OF_USE}>Terms of Use</FooterLink>{" "}
        -{" "}
        <FooterLink href={ROUTES.LANDING.PRIVACY_POLICY}>
          Privacy Policy
        </FooterLink>
      </Paragraph>
    </LandingSection>
  );
}
