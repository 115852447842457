import Link from "next/link";
import { ReactNode, useContext, useState } from "react";
import { MenuContext } from "src/landing/context/MenuContext";

export interface NavMenuLinkProps {
  to: string;
  children: ReactNode;
}

export function NavMenuLink({ to, children }: NavMenuLinkProps) {
  const [isHover, setIsHover] = useState(false);
  const { isOpen, setIsOpen } = useContext(MenuContext);

  return (
    <Link
      href={to}
      className="lg:text-xs font-semibold uppercase px-3.5 py-3.5"
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      onClick={() => setIsOpen(false)}
    >
      <div
        className={`relative after:content-[''] after:absolute after:w-full after:h-[2px] after:bg-counterGreen-600 after:bottom-0 after:left-0 after:transition-transform after:ease-out after:origin-top-right ${
          isHover ? "after:!origin-top-left after:scale-x-1" : "after:scale-x-0"
        }`}
      >
        {children}
      </div>
    </Link>
  );
}
