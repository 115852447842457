import { deserializeMember } from "src/api/serializers/authenticatedMember";
import { getMember } from "../hooks/useMember";
import { isValidToken } from "src/api/clients/loginClient";

export async function getIsUserActivated() {
  try {
    const hasToken = isValidToken();
    if (!hasToken) {
      return [false, false];
    }

    const member = await getMember();
    const deserializedMember = member ? deserializeMember(member) : null;
    const isActivated = !!deserializedMember?.activatedOn;
    return [!!deserializedMember, isActivated];
  } catch (e) {
    return [false, false];
  }
}
