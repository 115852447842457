import { Member as BackendMember } from "src/common/types/backend";
import { Member } from "src/common/types/index";
import { deserializeAddress } from "./address";
import cookies from "src/common/utils/cookies";
import { COOKIES_KEYS } from "src/common/config/cookies";

export function deserializeMember(responseObject: BackendMember) {
  const { getCookie } = cookies();
  const isImpersonating = getCookie(COOKIES_KEYS.IMPERSONATING);
  const impersonatingActivated =
    getCookie(COOKIES_KEYS.IMPERSONATING_ACTIVATED) || "";
  const {
    activated_on: activatedOn,
    alternate_id: alternateId,
    cert_number: certNumber,
    claim_member_id: claimMemberId,
    claimant_number: claimantNumber,
    created_on: createdOn,
    data,
    deactivated_on: deactivatedOn,
    dob,
    employee_id: employeeId,
    employer: employer,
    employer_group: employerGroup,
    family_id: familyId,
    gender,
    id,
    member_id: memberId,
    money_transmitter: moneyTransmitter,
    person_code: personCode,
    referral_code: referralCode,
    personal_email: personalEmail,
    phi_consent_on: phiConsentOn,
    phone_number: phoneNumber,
    plan_number: planNumber,
    primary_member: primaryMember,
    relationship,
    tos_consent_on: tosConsentOn,
    updated_on: updatedOn,
    user,
    work_email: workEmail,
  } = responseObject;

  let adjustedActivatedOn = activatedOn;

  if (isImpersonating) {
    adjustedActivatedOn =
      impersonatingActivated.toLowerCase() === "true"
        ? new Date().toISOString()
        : "";
  }

  const address = responseObject.address
    ? deserializeAddress(responseObject.address)
    : null;

  const response: Member = {
    address,
    activatedOn: adjustedActivatedOn,
    alternateId,
    certNumber,
    claimMemberId,
    claimantNumber,
    createdOn,
    data,
    deactivatedOn,
    dob,
    employeeId,
    employer,
    employerGroup,
    familyId,
    gender,
    id,
    memberId,
    moneyTransmitter,
    personCode,
    referralCode,
    personalEmail,
    phiConsentOn,
    phoneNumber,
    planNumber,
    primaryMember,
    relationship,
    tosConsentOn,
    updatedOn,
    user,
    workEmail,
  };

  return response;
}
