"use client";
import { COOKIES_KEYS } from "src/common/config/cookies";
import { useSearchParams } from "next/navigation";
import cookies from "src/common/utils/cookies";

interface UTMParams {
  utm_source: string | null;
  utm_campaign: string | null;
  utm_medium: string | null;
}

export function useRememberUTM() {
  const { getCookie, setCookie } = cookies();
  const searchParams = useSearchParams();
  let utm = {} as UTMParams;

  const parseUTM = () => {
    let utm: UTMParams;
    try {
      utm = JSON.parse(getCookie(COOKIES_KEYS.UTM_SOURCE) ?? "{}");
    } catch {
      utm = {} as UTMParams;
    }
    return utm;
  };

  if (typeof window !== "undefined") {
    if (getCookie(COOKIES_KEYS.UTM_SOURCE)) utm = parseUTM();

    if (searchParams?.get("utm_source"))
      utm.utm_source = searchParams?.get("utm_source");
    if (searchParams?.get("utm_campaign"))
      utm.utm_campaign = searchParams?.get("utm_campaign");
    if (searchParams?.get("utm_medium"))
      utm.utm_medium = searchParams?.get("utm_medium");

    if (utm) {
      setCookie(COOKIES_KEYS.UTM_SOURCE, JSON.stringify(utm));
    }
  }
  return { utm };
}
