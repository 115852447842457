import { Address as BackendAddress } from "src/common/types/backend";
import { Address } from "src/common/types/index";

export function deserializeAddress(responseObject: BackendAddress) {
  const {
    city,
    country,
    postal_code: postalCode,
    state,
    street1,
    street2,
  } = responseObject;

  const response: Address = {
    city,
    country,
    postalCode,
    state,
    street1,
    street2,
  };

  return response;
}
