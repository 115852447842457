"use client";
import { COOKIES_KEYS } from "src/common/config/cookies";
import { useSearchParams } from "next/navigation";
import cookies from "src/common/utils/cookies";

interface ReferralCodeParams {
  referral: string | null;
}

export function useRememberReferralCode() {
  const { getCookie, setCookie } = cookies();
  const searchParams = useSearchParams();
  let referralCode = "";

  if (typeof window !== "undefined") {
    if (getCookie(COOKIES_KEYS.REFERRAL_CODE))
      referralCode = getCookie(COOKIES_KEYS.REFERRAL_CODE);

    if (searchParams?.get("referral")) {
      referralCode = searchParams?.get("referral") || "";
    }

    if (referralCode !== "") {
      setCookie(COOKIES_KEYS.REFERRAL_CODE, referralCode);
    }
  }
  return { referralCode };
}
