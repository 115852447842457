import { ApiResult, fetcher } from "src/api/clients/api";
import { deserializeMember } from "src/api/serializers/authenticatedMember";
import { CLIENT_ENDPOINTS } from "src/common/config/client-endpoints";
import { Member as BackendMember } from "src/common/types/backend";
import { MemberUpdate } from "src/common/types/index";
import { getBackendBaseUrl } from "src/common/utils/get-backend-base-url";
import { getAuthorizationHeader } from "src/common/utils/helpers";
import { formatErrorMessage } from "src/common/utils/format-error-message";
import useSWR from "swr";

const API_URL: string = `${getBackendBaseUrl()}${CLIENT_ENDPOINTS.MEMBER}`;

export async function getMember(url: string = API_URL) {
  const { data, error } = await fetcher<ApiResult<BackendMember>>(
    url,
    "GET",
    null,
    getAuthorizationHeader(),
  );

  if (error) {
    console.error(error);
    throw error;
  }

  return data;
}

export interface UseMemberArgs {
  shouldFetch: boolean;
}

export function useMember(
  { shouldFetch }: UseMemberArgs = { shouldFetch: true },
) {
  const { data, isLoading, error, mutate } = useSWR<
    BackendMember | undefined,
    Error
  >(shouldFetch ? API_URL : null, getMember);

  async function refetch() {
    try {
      const data = await mutate<BackendMember>();
      return !!data ? deserializeMember(data) : null;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function updateMember(payload: MemberUpdate) {
    const { data, error } = await fetcher<ApiResult<BackendMember>>(
      API_URL,
      "PUT",
      JSON.stringify({
        phi_consent: payload.phiConsent,
      }),
      getAuthorizationHeader(),
    );

    if (error) throw new Error(formatErrorMessage(error));
    await mutate(data, { revalidate: false });
  }

  const member = data ? deserializeMember(data) : null;

  return {
    error,
    isLoading,
    member,
    refetch,
    updateMember,
  };
}
